import React, { useState, useEffect, useRef } from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import { useSession } from '../SessionContext';
import Header from './Header.tsx';
import Footer from './Footer.tsx';
import Layout from './Layout.tsx';
import { Card, IconButton, AspectRatio, CardContent, Stack, Select, Option, Grid, Container, CircularProgress } from '@mui/joy';
import { BookmarkAdd, DeleteOutline, Print, FileUpload } from '@mui/icons-material';
import moment from 'moment';
import OrientedImage from './OrientedImage';
import { OpenCvProvider } from 'opencv-react';

import API from '../lib/API.js';

import bootsLogo from '../images/boots-logo.svg';
import snapfishLogo from '../images/snapfish-logo.png';

const Dashboard = () => {
    const { session, logout, refreshSession } = useSession();
    const [fullPageLoading, setFullPageLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [selectedPrintingService, setSelectedPrintingService] = useState();
    const [imageSizes, setImageSizes] = useState('mixed');
    const fileInputRef = useRef(null);

    const serverUrl = process.env.REACT_APP_SERVER_URL;
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        init();
    }, []);

    async function init() {
        try {
            await refreshSession();
            
            if (session.images.length > 0) {
                if (session.images.every(img => img.settings.size === session.images[0].settings.size)) {
                    setImageSizes(session.images[0].settings.size);
                } else {
                    setImageSizes('mixed');
                }

                console.log(imageSizes)
            }
            const service = session.printingServices.find(service => service.id === session.activeJob.printingService);
            setSelectedPrintingService(service);
            
            setFullPageLoading(false);
        } catch (error) {
            console.error('Error initializing dashboard:', error);
            setFullPageLoading(false);
        }
    }

    async function deleteImage(id) {
        setLoading(true);
        const response = await API.deleteImage(id);
        if (response.ok) {
            await init();
            setLoading(false);
        }
    }

    async function updateImageSize(image, size) {
        image.settings.size = size;
        await updateImage(image);
    }

    async function updateAllImageSizes(size) {
        showLoading();
        const response = await API.updateAllImageSizes(session, size);
        if (response.ok) {
            await init();
            hideLoading();
        }
    }

    async function updateImageQuantity(image, quantity) {
        image.settings.quantity = quantity;
        await updateImage(image);
    }

    async function updateImage(image) {
        showLoading();
        const response = await API.updateImage(image); 
        if (response.ok) {
            await init();
            hideLoading();
        }
    }

    async function setPrintingService(printingService) {
        showLoading();
        const response = await API.setPrintingService(session, printingService);
        if (response) {
            await init();
            hideLoading();
        }
    }

    function showLoading() {
        setLoading(true);
    }

    function hideLoading() {
        setTimeout(() => {
            setLoading(false);
        }, 300);
    }

    async function handleFileUpload(event) {
        const files = event.target.files;
        if (files.length > 0) {
            if (session.images.length === 0) {
                setFullPageLoading(true);
            } else {
                setLoading(true);
            }
            const response = await API.uploadFiles(session, files);
            if (response.ok) {
                await refreshSession();
                setFullPageLoading(false);
                setLoading(false);
            }
        }
    }

    return (
        <CssVarsProvider>
            <Layout.Header>
                <Header />
            </Layout.Header>
            {fullPageLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                    <CircularProgress size="lg" />
                </Box>
            ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ flexGrow: 1, p: 3, mb: 13 }}>
                        {session.images.length === 0 && (
                            <>
                                <Typography level="h2" component="h1" sx={{ mb: 2 }}>
                                    My Photos
                                </Typography>
                                <Box
                                    sx={(theme) => ({
                                        height: '70vh',
                                        width: '100%',
                                        position: 'relative',
                                        alignItems: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        gap: '2rem',
                                    })}
                                >
                                    <Button
                                        variant="solid"
                                        size="lg"
                                        color="primary"
                                        sx={{ ml: 2, fontSize: '2rem', padding: '1rem 2rem' }}
                                        onClick={() => fileInputRef.current.click()} // Trigger file input click
                                    >
                                        <FileUpload sx={{ mr: 1, fontSize: '2.5rem' }} />
                                        Upload Images
                                    </Button>
                                    <input
                                        type="file"
                                        multiple
                                        accept="image/jpeg"
                                        onChange={handleFileUpload}
                                        ref={fileInputRef} // Attach ref to the file input
                                        style={{ display: 'none' }}
                                    />
                                    <Box style={{ fontSize: '1.2rem' }}>
                                        You can also press Ctrl+P to print from your computer or email images to <a href="mailto:print@ezepix.com">print@ezepix.com</a> at any time.
                                    </Box>
                                </Box>
                            </>
                        )}
                        {session.images.length > 0 && (
                            <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                                <Grid item>
                                    <Typography level="h2" component="h1" sx={{ mb: 2 }}>
                                        My Photos
                                    </Typography>
                                    <Typography level="p" component="p" sx={{ mb: 2 }}>
                                        You can add images by printing via your computer or emailing to <a href="mailto:print@ezepix.com">print@ezepix.com</a> at any time.
                                    </Typography>
                                </Grid>
                                <Grid item display="flex" flexDirection="row" alignItems="flex-end">
                                    {loading && (
                                        <CircularProgress size="md" sx={{ mr: 2 }} />
                                    )}

                                    <Select size="lg" value={imageSizes || '7x5'} onChange={(e, newValue) => updateAllImageSizes(newValue)} sx={{ ml: 2 }}>
                                        {imageSizes === 'mixed' && (
                                            <Option value="mixed">Mixed sizes</Option>
                                        )}
                                        <Option value="6x4">6" x 4"</Option>
                                        <Option value="7x5">7" x 5"</Option>
                                    </Select>

                                    <Button
                                        variant="solid"
                                        size="lg"
                                        color="primary"
                                        sx={{ ml: 2 }}
                                        onClick={() => fileInputRef.current.click()} // Trigger file input click
                                    >
                                        <FileUpload sx={{ mr: 1 }} />
                                        Upload Images
                                    </Button>
                                    <input
                                        type="file"
                                        multiple
                                        accept="image/*"
                                        onChange={handleFileUpload}
                                        ref={fileInputRef} // Attach ref to the file input
                                        style={{ display: 'none' }}
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {session.images.length > 0 && (
                            <>
                                <Stack direction="row" spacing={2} flexWrap="wrap" useFlexGap>
                                    {session.images.map((img, index) => (
                                        <Card sx={{ width: `calc(${100 / 4}% - 48px)` }} key={img.id}>
                                            <div>
                                                <Typography level="title-lg">
                                                    {img.settings?.attributes && img.settings.attributes.description || `Image ${index + 1}`}
                                                </Typography>
                                                <Typography level="body-sm">
                                                    added {moment(img.createdAt).fromNow()}
                                                </Typography>
                                            </div>
                                            <AspectRatio ratio={4/3} objectFit='contain'>
                                                <img
                                                    src={API.getImageUrl(img.url, 600, img.settings.metadata?.rotationAngle)}
                                                    loading="lazy"
                                                    alt=""
                                                />
                                            </AspectRatio>
                                            <CardContent orientation="horizontal">
                                                <div>
                                                    <Select value={img.settings.quantity || 1} onChange={(e, newValue) => updateImageQuantity(img, newValue)}>
                                                        {[...Array(100).keys()].map(quantity => (
                                                            <Option value={quantity + 1} key={quantity + 1}>{quantity + 1}</Option>
                                                        ))}
                                                    </Select>
                                                </div>
                                                <div>
                                                    <Select value={img.settings.size || '7x5'} onChange={(e, newValue) => updateImageSize(img, newValue)}>
                                                        <Option value="6x4">6" x 4"</Option>
                                                        <Option value="7x5">7" x 5"</Option>
                                                    </Select>
                                                </div>
                                                <Button
                                                    variant="solid"
                                                    size="md"
                                                    color="primary"
                                                    aria-label="Explore Bahamas Islands"
                                                    sx={{ ml: 'auto', alignSelf: 'center', fontWeight: 600 }}
                                                    onClick={() => deleteImage(img.id)}
                                                >
                                                    Delete
                                                </Button>
                                            </CardContent>
                                        </Card>
                                    ))}
                                </Stack>
                                <Box
                                    sx={{
                                        width: '100%',
                                        position: 'fixed',
                                        bottom: 0,
                                        left: 0,
                                        bgcolor: '#fff',
                                        color: 'white',
                                        py: 3,
                                        px: 0,
                                        boxShadow: '0 -1px 5px rgba(0,0,0,0.1)',
                                        zIndex: 1000
                                    }}
                                >
                                    <Container maxWidth="100%">
                                        <Grid container justifyContent="space-between" alignItems="center" sx={{ maxWidth: '100%', width: '100%', padding: '0 12px' }}>
                                            <Grid item>
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <Select value={session.activeJob.printingService} size="lg" sx={{ mr: 4 }} onChange={(e, newValue) => setPrintingService(newValue)} startDecorator={<img src={selectedPrintingService?.image} height={25} />}>
                                                        {session.printingServices.map(service => (
                                                            <Option value={service.id} key={service.id}>
                                                                <img src={service.image} width={50} />
                                                                {service.description}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                    <Typography variant="body2" sx={{ mr: 1 }}>
                                                        <b>Printing:</b> £{session.estimatedPrice.photos?.value.toFixed(2)}
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ mr: 1 }}>
                                                        <b>Postage:</b> £{session.estimatedPrice.delivery?.value.toFixed(2)}
                                                    </Typography>
                                                    <Typography variant="body2" sx={{ mr: 1 }}>
                                                        <b>Total:</b> £{(session.estimatedPrice.photos?.value + session.estimatedPrice.delivery?.value).toFixed(2)}
                                                    </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="solid"
                                                    size="lg"
                                                    color="primary"
                                                    component="a"
                                                    href="/order"
                                                    sx={{ ml: 'auto', alignSelf: 'center', fontWeight: 600 }}
                                                >
                                                    Order {session.estimatedPrice.photos?.quantity} Prints <Print sx={{ ml: 1 }} />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Container>
                                </Box>

                            </>
                        )}
                    </Box>
                </Box>
            )}
            <canvas id="canvas" style={{ display: 'none' }}></canvas>
        </CssVarsProvider>
    );
};

export default Dashboard;
