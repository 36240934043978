import React, { useState, useEffect } from 'react';
import { CssVarsProvider } from '@mui/joy/styles';
import Box from '@mui/joy/Box';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import { useSession } from '../SessionContext';
import Header from './Header.tsx';
import Layout from './Layout.tsx';
import { Table, Stack, Select, Option, Grid, CircularProgress, FormControl, FormLabel, IconButton, Modal, ModalDialog } from '@mui/joy';
import { KeyboardArrowLeft as KeyboardArrowLeftIcon, KeyboardArrowRight as KeyboardArrowRightIcon } from '@mui/icons-material';
import moment from 'moment';
import PolaroidList from './PolaroidList.jsx';
import API from '../lib/API.js';

const OrderHistory = () => {
    const { session } = useSession();
    const [loading, setLoading] = useState(true);
    const [jobs, setJobs] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [selectedImage, setSelectedImage] = useState(null);
    const [adding, setAdding] = useState(false);

    useEffect(() => {
        async function loadImages() {
            const loadedJobs = [];
            for (let job of session.previousJobs) {
                const images = await API.getImagesForJob(job.id);
                job.photos = images;
                loadedJobs.push(job);
            }
            setJobs(loadedJobs);
            hideLoading();
        }

        loadImages();
    }, [session]);

    function hideLoading() {
        setTimeout(() => {
            setLoading(false);
        }, 300);
    }

    const handleChangePage = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(0);
    };

    const getLabelDisplayedRowsTo = () => {
        if (jobs.length === -1) {
            return (currentPage + 1) * rowsPerPage;
        }
        return Math.min(jobs.length, (currentPage + 1) * rowsPerPage);
    };

    const labelDisplayedRows = ({ from, to, count }) => {
        return `${from}-${to} of ${count !== -1 ? count : `more than ${to}`}`;
    };

    const currentJobs = jobs.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);

    const handleImageClick = (image) => {
        setSelectedImage(image);
    };

    const handleAddToMyPhotos = async () => {
        setAdding(true);
        await API.addPhotoToMyPhotos(selectedImage, session);
        setAdding(false);
        setSelectedImage(null);
    };

    return (
        <CssVarsProvider>
            <Layout.Header>
                <Header />
            </Layout.Header>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ flexGrow: 1, p: 3, mb: 13 }}>
                    {session.previousJobs.length === 0 && (
                        <>
                            <Typography level="h2" component="h1" sx={{ mb: 2 }}>
                                My Order History
                            </Typography>
                            <Typography level="p" component="p" sx={{ mb: 2 }}>
                                You have no previous orders
                            </Typography>
                        </>
                    )}
                    {session.previousJobs.length > 0 && (
                        <Grid container justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                            <Grid item>
                                <Typography level="h2" component="h1" sx={{ mb: 2 }}>
                                    My Order History
                                </Typography>
                                <Typography level="p" component="p" sx={{ mb: 2 }}>
                                    Your previous orders are shown below. 
                                </Typography>
                            </Grid>
                            <Grid item display="flex" flexDirection="row" alignItems="flex-end">
                                {loading && (
                                    <CircularProgress size='md' />
                                )}
                            </Grid>
                        </Grid>
                    )}
                    {currentJobs.length > 0 && (
                        <>
                            <Stack direction="row" spacing={2} flexWrap="wrap" useFlexGap>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th width="20%">Date</th>
                                            <th width="10%">Status</th>
                                            <th width="10%">Printer</th>
                                            <th width="60%">Photos</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentJobs.map(job => (
                                            <tr key={job.id}>
                                                <td>
                                                    {moment(new Date(job.createdAt)).format('dddd, DD MMMM YYYY')}
                                                </td>
                                                <td>
                                                    {job.status}
                                                </td>
                                                <td>
                                                    PRINTER
                                                </td>
                                                <td align="left">
                                                    <PolaroidList images={job.photos.slice(0, 12)} onImageClick={handleImageClick} />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Stack>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2,
                                    justifyContent: 'flex-end',
                                    mt: 2
                                }}
                            >
                                <Typography sx={{ textAlign: 'center', minWidth: 80 }}>
                                    {labelDisplayedRows({
                                        from: jobs.length === 0 ? 0 : currentPage * rowsPerPage + 1,
                                        to: getLabelDisplayedRowsTo(),
                                        count: jobs.length,
                                    })}
                                </Typography>
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    <IconButton
                                        size="sm"
                                        color="neutral"
                                        variant="outlined"
                                        disabled={currentPage === 0}
                                        onClick={() => handleChangePage(currentPage - 1)}
                                        sx={{ bgcolor: 'background.surface' }}
                                    >
                                        <KeyboardArrowLeftIcon />
                                    </IconButton>
                                    <IconButton
                                        size="sm"
                                        color="neutral"
                                        variant="outlined"
                                        disabled={
                                            jobs.length !== -1
                                                ? currentPage >= Math.ceil(jobs.length / rowsPerPage) - 1
                                                : false
                                        }
                                        onClick={() => handleChangePage(currentPage + 1)}
                                        sx={{ bgcolor: 'background.surface' }}
                                    >
                                        <KeyboardArrowRightIcon />
                                    </IconButton>
                                </Box>
                            </Box>
                        </>
                    )}
                </Box>
            </Box>

            {selectedImage && (
                <Modal open={true} onClose={() => setSelectedImage(null)}>
                    <ModalDialog>
                        <img 
                            src={API.getImageUrl(selectedImage.url, 600)} 
                            alt="Selected" 
                            style={{ width: '100%', maxHeight: 'calc(100vh - 200px)' }} 
                        />
                        <Button onClick={handleAddToMyPhotos} sx={{ mt: 2 }} disabled={adding}>
                            {adding ? (
                                <>
                                    <CircularProgress size="sm" sx={{ mr: 1 }} />
                                    Adding to My Photos...
                                </>
                            ) : (
                                "Add to My Photos"
                            )}
                        </Button>
                    </ModalDialog>
                </Modal>
            )}
        </CssVarsProvider>
    );
};

export default OrderHistory;
